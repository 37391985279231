import React, { Component } from "react";
import axios from "axios";
import * as PropTypes from "prop-types";
import { Col, DatePicker, Form, Input, InputNumber, message, Modal, Tabs, Row, Select, Spin, Switch } from "antd";
import MaskedInput from "react-text-mask"

import moment from "moment";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { customerService, resalesService } from "./../../redux/services";

import {
	DatePickerWithMask,
	UIDrawerForm,
	UIUpload,
	UILabelHelp,
} from "./../../components";

import Adresses from "./adresses/index";
import Contacts from "./contacts/index";
import Users from "./users/index";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

const { TextArea } = Input;

class Edit extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
			uuid: 0,
			isSendingZipcode: false,
			resales: [],
			resalesIsLoading: false,
			tipo_pessoa: null,
			app_funcionario: null,
			app_empresa: null,
		};

		this._axiosCancelResalesToken = null;
	}

	fetchResales = (value) => {
		if (this._axiosCancelResalesToken) {
			this._axiosCancelResalesToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelResalesToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				resalesIsLoading: false,
				resales: [],
			});

			return false;
		}

		this.setState({
			resalesIsLoading: true,
		});

		resalesService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelResalesToken.token,
		})
			.then((response) => {
				this.setState({
					resalesIsLoading: false,
					resales: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					resalesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
		});

		customerService.show({ uuid })
			.then((response) => {
				let item = response.data.data;

				let resales = [];

				if (item.revenda) {
					resales.push({
						id: item.revenda.id,
						nome: item.revenda.nome,
					});
				}

				this.setState({
					isLoading: false,
					resales: resales,
				}, () => {
					// Fill form
					this.tabAdresses && this.tabAdresses.init(item);
					this.tabContacts && this.tabContacts.init(item);
					this.tabUsers && this.tabUsers.init(item);
					this.fillForm(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	fillForm = (data) => {
		this.form.setFieldsValue({
			nome: data.nome,
			tipo_contrato: data.tipo_contrato,
			tipo_pessoa: data.tipo_pessoa,
			cpf: data.cpf,
			cnpj: data.cnpj,
			razao_social: data.razao_social,
			limite_funcionario: data.limite_funcionario,
			revenda_id: data.revenda_id,
			slug: data.slug,
			observacoes: data.observacoes,
			vencimento: data.vencimento ? moment(data.vencimento) : null,
			nascimento: data.nascimento ? moment(data.nascimento) : null,
			status: data.status,
			rg: data.rg,
			im: data.im,
			ie: data.ie,
		});

		this.setState({
			tipo_pessoa: data.tipo_pessoa,
			app_empresa: data.app_empresa,
			app_funcionario: data.app_funcionario,
		})

	};

	resetFields = () => {
		this.form.resetFields();
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		// const file = this.upload.getFiles();

		// if (file.hasError) {
		// 	Modal.error({
		// 		title: "Ocorreu um erro!",
		// 		content: file.error,
		// 	});

		// 	return false;
		// }

		this.setState({
			isSending: true,
		});

		const { uuid } = this.state;

		const data = {
			...values
		};

		// uuid
		data.uuid = uuid;

		// File
		// if (file.files.length) {
		// 	if (!file.files[0].uuid) {
		// 		data.avatar = file.files[0];
		// 	}
		// }
		// else {
		// 	data.avatar = null;
		// }

		// birth
		if (data.vencimento) {
			data.vencimento = data.vencimento.format("YYYY-MM-DD");
		} else {
			delete data.vencimento;
		}

		if (data.nascimento) {
			data.nascimento = data.nascimento.format("YYYY-MM-DD");
		} else {
			delete data.nascimento;
		}

		customerService.edit(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Registro atualizado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	render() {
		const { visible } = this.props;

		const { uuid, isLoading, isSending, tipo_pessoa, app_funcionario, app_empresa, resales, resalesIsLoading } = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title={`Editar Cliente`}>
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}>
					<Tabs defaultActiveKey="general" >
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Row gutter={16}>
								<Col xs={24} sm={24}>
									<Form.Item name="nome" label="Nome" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item name="limite_funcionario" label="Limite de funcionários" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<InputNumber style={{ width: '100%' }} />
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item name="slug" label={<UILabelHelp title="Slug" content={"URL da página relativo ao site\nex.: https://slug.byponto.com.br"} />} hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input onKeyUp={(evt) => {
											this.form.setFieldsValue({ slug: evt.currentTarget.value.replace(/[^0-9_a-z]/g, "") })

										}} maxLength={30} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item name="revenda_id" label="Revenda" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											filterOption={false}
											allowClear
											placeholder="Escolha a revenda"
											notFoundContent={resalesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
											onSearch={this.fetchResales}
											showSearch
										>
											{resales.map((item, index) => (
												<Select.Option key={index} value={item.id}>
													{item.nome}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item name="tipo_pessoa" label="Tipo de pessoa" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											onChange={(value) => { this.setState({ tipo_pessoa: value }) }}
											showSearch>
											<Select.Option value="Física">Física</Select.Option>
											<Select.Option value="Jurídica">Jurídica</Select.Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>
							{tipo_pessoa != null && tipo_pessoa == "Física" ? (
								<Row gutter={16}>
									<Col xs={24} sm={8}>
										<Form.Item name="rg" label="RG" hasFeedback >
											<MaskedInput
												mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/]}
												className="ant-input"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={8}>
										<Form.Item name="cpf" label="CPF" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
											<MaskedInput
												mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
												className="ant-input"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={8}>
										<DatePickerWithMask onKeyUp={(e) => {
											if (e.keyCode === 13 || e.keyCode === 9) {
												this.tipo_contrato.focus()
											}
										}} name="nascimento" label="Data de nascimento" disableDateAfter={true} />
									</Col>
								</Row>
							) : (tipo_pessoa && tipo_pessoa.length > 0 && (
								<>
									<Row gutter={16}>
										<Col xs={24} sm={8}>
											<Form.Item name="cnpj" label="CNPJ" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
												<MaskedInput
													mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
													className="ant-input"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={8}>
											<Form.Item name="ie" label="IE" hasFeedback >
												<Input style={{ width: '100%' }} />
											</Form.Item>
										</Col>
										<Col xs={24} sm={8}>
											<Form.Item name="im" label="IM" hasFeedback >
												<Input style={{ width: '100%' }} />
											</Form.Item>
										</Col>
									</Row>
								</>
							))}
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item name="tipo_contrato" label="Tipo de contrato" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											ref={el => this.tipo_contrato = el}
											allowClear
											showSearch>
											<Select.Option value="Mensal">Mensal</Select.Option>
											<Select.Option value="Anual">Anual</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<DatePickerWithMask onKeyUp={(e) => {
										if (e.keyCode === 13 || e.keyCode === 9) {
											this.observacoes.focus()
										}
									}} name="vencimento" label="Data de vencimento" required={true} disableDateBefore={true} />
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={24}>
									<Form.Item name="observacoes" label="Observações">
										<TextArea ref={el => this.observacoes = el} autoSize={{ minRows: 2, maxRows: 5 }} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col sm={8}>
									<Form.Item name="status" label="Status" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											showSearch
										>
											<Select.Option value="Ativo">Ativo</Select.Option>
											<Select.Option value="Inativo">Inativo</Select.Option>
											<Select.Option value="Bloqueado">Bloqueado</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col sm={8}>
									<Form.Item name="app_funcionario" label="App Funcionário" >
										<Switch checked={app_funcionario == 1 ? true : false} onChange={(value) => this.setState({ app_funcionario: value })} />
									</Form.Item>
								</Col>
								<Col sm={8}>
									<Form.Item name="app_empresa" label="App Empresa">
										<Switch checked={app_empresa == 1 ? true : false} onChange={(value) => this.setState({ app_empresa: value })} />
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Endereços" key="adresses">
							<Adresses
								ref={el => this.tabAdresses = el}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Contatos" key="anamnese">
							<Contacts
								ref={el => this.tabContacts = el}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Usuários" key="users">
							<Users
								ref={el => this.tabUsers = el}
							/>
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Edit;
